<template>
  <VueDropzone
    id="dropzone"
    ref="DropZone"
    :options="options"
    @vdropzone-complete="afterUploadComplete"
  ></VueDropzone>
</template>

<script>
import VueDropzone from "vue2-dropzone";

export default {
  props: {
    options: Object,
    image: String,
  },
  mounted() {
    if (this.image !== "") {
      var file = { size: 123000, name: "Icon", type: "*/*" };
      var url = "https://dev.api.callstrategy.fr/" + this.image;
      this.$refs.DropZone.manuallyAddFile(file, url);
    }
  },
  components: {
    VueDropzone,
  },
  methods: {
    afterUploadComplete: async function (response) {
      this.$emit("change", response);
    },
  },
};
</script>

<style></style>
